$body-font: Arial, Helvetica, "sans-serif";

body {
  font-family: $body-font;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  margin: 1em;
  z-index: 10;
}

@import './style/modal.scss';
@import './style/camera.scss';
@import './style/logo.scss';
@import './style/result.scss';
@import './style/apropos.scss';
@import './style/error.scss';
