#errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    text-align: center;
    background: transparent;
}

#error {
    margin: 0 2em;
}
