.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em;
}

.text {
  max-width: 900px;
}

.center {
  text-align: center;
}

footer {
  margin-top: auto;
  margin-bottom: 1em;
  text-align: center;
  margin-bottom: 200px;
}

.bezigue {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateY(70%);
  transition: transform 1s ease-in-out;
}

.bezigue img {
  display: block;
}
