.modal {
  margin: 1em;
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 15px;

  &.is-active {
    display: flex;
  }
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.modal-content {
  margin: 0 20px;
  overflow: auto;
  position: relative;
  color: white;
  text-align: center;
  margin: 2em;
}

.modal-close {
  color: white;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  position: absolute;
  right: 0;
  padding: 0;
  top: 0;
  margin: 1em;
}
