body {
  font-family: Arial, Helvetica, "sans-serif";
}

.fixed-top {
  z-index: 10;
  margin: 1em;
  position: fixed;
  top: 0;
  right: 0;
}

.modal {
  z-index: 40;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #0a0a0adb;
  position: absolute;
  inset: 0;
}

.modal-content {
  color: #fff;
  text-align: center;
  margin: 2em;
  position: relative;
  overflow: auto;
}

.modal-close {
  color: #fff;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  border: none;
  border-radius: 9999px;
  margin: 1em;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

#preview {
  object-fit: cover;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}

#cameraBtn {
  z-index: 1;
  width: 6rem;
  height: 6rem;
  border-radius: 9999px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.logo {
  z-index: 4;
  width: 10rem;
  margin: 1em auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.logo.block {
  max-width: max-content;
  display: block;
  position: relative;
}

#currentPhoto {
  max-width: 100%;
  margin: 2em 0;
}

#result {
  margin: 0 2em;
}

.spin {
  height: 1em;
  width: 1em;
  animation: 2s linear infinite spin;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
  display: flex;
}

.text {
  max-width: 900px;
}

.center {
  text-align: center;
}

footer {
  text-align: center;
  margin-top: auto;
  margin-bottom: 200px;
}

.bezigue {
  transition: transform 1s ease-in-out;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateY(70%);
}

.bezigue img {
  display: block;
}

#errorContainer {
  height: 100vh;
  width: 100vw;
  text-align: center;
  background: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#error {
  margin: 0 2em;
}

/*# sourceMappingURL=index.fd75a498.css.map */
