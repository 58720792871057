.logo {
  z-index: 4;
  position: absolute;
  top: 0;
  margin: 1em;
  width: 10rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  &.block {
    position: relative;
    display: block;
    max-width: max-content;
  }
}
