#currentPhoto {
  margin: 2em 0;
  max-width: 100%;
}

#result {
  margin: 0 2em;
}

.spin {
  display: inline-block;
  height: 1em;
  width: 1em;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
